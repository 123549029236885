<template>
  <div id="muestras">
    <MenuBase
      :tipo="'muestras'"
      :headers="headers"
      :items="$store.state.tabla"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-water'"
      :verTabla="true"
      :loading="!$store.state.tabla.length"
    />
    <Muestra :modo="modoMuestra" :dialogMU.sync="dialogMuestra" :nmuestra.sync="this.campoOpcion" :origen.sync="origen"/>
  </div>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import MenuBase from '@/components/menubase.vue'
import Muestra from '@/views/muestra.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    Muestra
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Muestras',
    dialog: false,
    tituloOpciones: 'Opciones muestra: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    modoMuestra: 'NUEVA',
    dialogMuestra: false,
    origen: 'tabla'
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre' },
        { text: 'NOTAS', align: 'start', value: 'notas' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVA MUESTRA', color: 'primary', accion: 'nuevaMuestra' }
      ]
      this.opciones = [
        { id: 1, nombre: 'EDITAR MUESTRA', color: 'primary', accion: 'editarMuestra' }
        // { id: 2, nombre: 'QUITAR MUESTRA', color: 'error', accion: 'quitarMuestra' }
      ]
    },
    clicFila (fila) {
      this.campoOpcion = fila.nombre
      this.dialog = true
    },
    clicBoton (accion) {
      if (accion === 'nuevaMuestra') {
        this.modoMuestra = 'NUEVA'
        this.dialogMuestra = true
      } else if (accion === 'editarMuestra') {
        this.modoMuestra = 'EDITAR'
        this.dialogMuestra = true
      } else if (accion === 'quitarMuestra') {
        alert('Se quitará ésta muestra como parámetro seleccionable')
      }
    },
    cargarItems () {
      this.cargarTabla('muestras/todo', 'cambiarTabla', 'cargarTablaMuestras')
    },
    cargarDatos () {
      if (this.compruebaLogin() === true) {
        if (this.returnPrilegio('muestras') === true) {
          this.interfaz()
          this.cargarItems()
        } else { this.$router.push('/menu') }
      }
    }
  },
  created () {
    this.haySesion()
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
